import styled from "styled-components"

const FixedButton = styled.button`
  color: ${props => (props.primary ? "black" : "white")} !important;
  cursor: pointer;
  position: fixed;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  z-index: 999;
  font-size: calc(0.8vh + 14px);
  svg {
    height: 3vh;
    width: 1.8vh;
  }
  font-family: kanit;
  font-weight: 600 !important;
  &:hover {
    font-weight: 600 !important;
  }
  letter-spacing: inherit;

  @media (min-width: 800px) {
    top: 10px;
    right: 18px;
  }
  @media (max-width: 799px) {
    top: -2vh;
    right: 0;
  }
`

export { FixedButton }
